import React from "react";
import NavBar from "./components/NavBar"; // New NavBar component
import About from "./components/About";
import TechStack from "./components/TechStack";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  return (
    <div className="bg-white dark:bg-gray-900">
      {/* Navigation Bar */}
      <NavBar />

      <div className="px-6 md:px-16 pt-16">
        {/* Home Section */}
        <section id="home">
          <Header />
        </section>

        {/* About Section */}
        <section id="about">
          <About />
        </section>

        {/* Tech Stack Section */}
        <section id="techstack">
          <TechStack />
        </section>

        {/* Projects Section */}
        <section id="projects">
          <Projects />
        </section>
      </div>
      {/* Contact Section */}
      <section id="contact">
        <Footer />
      </section>
    </div>
  );
}

export default App;
