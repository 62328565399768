import React from "react";
// experience
import devlynx from "../assets/my Experience/devlynxtech_logo.png";
import codemasters from "../assets/my Experience/codeMasters.png";
// education
import ncba from "../assets/education/ncba.png";
import sc from "../assets/education/science college.png";
import pgs from "../assets/education/pgs.png";

function About() {
  return (
    <section className="py-12">
      <div className="mb-8 text-left">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
          About Me
        </h2>
        <p className="mt-4 text-gray-700 dark:text-gray-300">
          The Generator App is an online tool that helps you to export
          ready-made templates ready to work as your future website. It helps
          you to combine slides, panels, and other components and export it as a
          set of static files: HTML/CSS/JS.
        </p>
      </div>

      <div className="mb-12">
        <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
          Work Experience
        </h3>
        <div className="mt-6 space-y-4">
          <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
            <h4 className="text-xl font-semibold text-gray-900 dark:text-white">
              Full Stack Engineer
            </h4>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              <span>
                <img src={devlynx} alt="" className="inline w-10" />
              </span>
              DevLynx Technologies - PAKISTAN
            </p>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Oct 2023 - Present · 1 yr
              </span>
              <span className="bg-green-200 text-green-600 text-xs font-bold py-1 px-2 rounded">
                Full Time
              </span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
            <h4 className="text-xl font-semibold text-gray-900 dark:text-white">
              Full Stack Engineer Intern
            </h4>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              <span>
                <img src={codemasters} alt="" className="inline w-10" />
              </span>
              CodeMasters - INDIA
            </p>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Sep 2023 - Oct 2023 · 2 mos
              </span>
              <span className="bg-green-200 text-green-600 text-xs font-bold py-1 px-2 rounded">
                Internship
              </span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
          Education
        </h3>
        <div className="mt-6 bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
          <h4 className="text-xl font-semibold text-gray-900 dark:text-white">
            <span>
              <img src={ncba} alt="" className="inline w-10" />
            </span>
            National College Of Business Administration & Economics
          </h4>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            PAKISTAN - LAHORE
          </p>
          <div className="flex justify-between items-center mt-2">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Aug 2023 –
            </span>
            <span className="bg-green-200 text-green-600 text-xs font-bold py-1 px-2 rounded">
              PRESENT
            </span>
          </div>
        </div>
        <div className="mt-6 bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
          <h4 className="text-xl font-semibold text-gray-900 dark:text-white">
            <span>
              <img src={sc} alt="" className="inline w-10" />
            </span>
            Government College Of Science
          </h4>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            PAKISTAN - LAHORE
          </p>
          <div className="flex justify-between items-center mt-2">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Mar 2020 - Jan 2022
            </span>
            <span className="bg-green-200 text-green-600 text-xs font-bold py-1 px-2 rounded">
              Completed
            </span>
          </div>
        </div>
        <div className="mt-6 bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
          <h4 className="text-xl font-semibold text-gray-900 dark:text-white">
            <span>
              <img src={pgs} alt="" className="inline w-10" />
            </span>
            Pakistan Grammer School
          </h4>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            PAKISTAN - LAHORE
          </p>
          <div className="flex justify-between items-center mt-2">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Jan 2008 - Dec 2019
            </span>
            <span className="bg-green-200 text-green-600 text-xs font-bold py-1 px-2 rounded">
              Completed
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
