import React from "react";
import GrapesoftHiringPlateForm from "../assets/projects/Grapesoft Hiring App.PNG";
const projects = [
  {
    title: "GrapeSoft Hiring PlateForm",
    description:
      "Grapesoft Hiring App is a streamlined recruitment platform designed to automate and enhance the hiring process for small and medium-sized businesses. With features like automated candidate links, real-time test monitoring, and a centralized admin dashboard, it simplifies managing candidate data and evaluations. The app offers secure data handling, boosts hiring efficiency, and ensures transparency with real-time insights, making it an ideal tool for companies looking to optimize their recruitment efforts.",
    image: GrapesoftHiringPlateForm,
    liveLink: null,
    codeLink: null,
  },
];

function Projects() {
  return (
    <section className="py-12 text-center">
      <h2 className="text-3xl font-bold dark:text-white">Projects</h2>
      <div className="mt-8 flex flex-wrap justify-center gap-8">
        {projects.map((project, index) => (
          <div
            key={index}
            className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 w-full md:w-1/3 flex flex-col items-center"
          >
            <img
              src={project.image}
              alt={project.title}
              className="rounded-t-lg w-full"
            />
            <h3 className="text-xl font-semibold dark:text-white mt-4 text-center">
              {project.title}
            </h3>
            <p className="text-gray-700 dark:text-gray-400 mt-2 text-center">
              {project.description}
            </p>
            <div className="mt-4 text-center">
              {project.liveLink && (
                <a
                  href={project.liveLink}
                  className="text-blue-500 dark:text-blue-400 mr-4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live
                </a>
              )}
              {project.codeLink && (
                <a
                  href={project.codeLink}
                  className="text-blue-500 dark:text-blue-400"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;
