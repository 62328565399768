import React from "react";

function Footer() {
  return (
    <footer className="py-8 text-center bg-gray-200 dark:bg-gray-900 dark:text-gray-300">
      <p>For any questions, please mail me at <a href="mailto:hi@pavanmg.in" className="text-blue-500 dark:text-blue-400">mantiqsoft@gmail.com</a></p>
      <p className="mt-4 text-gray-600 dark:text-gray-500">&copy; 2024 Abdul Wadood. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
