import React from "react";
import profileImage from "../assets/Profile/profileImage.jpg"; // Ensure you have the image in your assets folder

function Header() {
  return (
    <header className="py-8 flex items-center sm:gap-44 space-x-8">
      {/* Left: Text Section */}
      <div className="text-left">
        <div className="sm:text-5xl text-3xl">
          <h1 className="font-bold dark:text-white">
            Hi{" "}
            <span role="img" aria-label="wave">
              👋
            </span>
            ,
          </h1>
          <h1 className="font-bold dark:text-white">
            My name is <span className="text-blue-500">Abdul Wadood</span>
          </h1>
        </div>
        <p className="text-2xl mt-2 font-semibold text-gray-700 dark:text-white">
          I'm a software developer with a passion for building innovative
          solutions.
        </p>
      </div>

      {/* Right: Profile Image Section */}
      <div>
        <img
          src={profileImage}
          alt="Profile"
          className="rounded-full border-4 border-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 p-1 sm:w-[1000px] object-cover"
        />
      </div>
    </header>
  );
}

export default Header;
